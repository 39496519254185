import React, { createContext, useContext, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { Consent, Consents, ICookiePolicy } from '../type';

interface IDidomiUserStatus {
  purposes: Record<
    'measure_ad_performance' | 'improve_products',
    { enabled: boolean }
  >;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    didomiEventListeners?: Array<{
      event: string;
      listener: (...args: any) => void;
    }>;
    didomiOnReady?: Array<(...args: any) => void>;
    Didomi?: {
      getCurrentUserStatus: () => IDidomiUserStatus;
      preferences: {
        show: () => void;
      };
    };
  }
}

const context = createContext<{
  consents: Consents;
}>({
  consents: {},
});

const Provider: ICookiePolicy['CookieProvider'] = ({ children, onConsent }) => {
  const [consents, setConsents] = useState<Partial<Record<Consent, boolean>>>({
    adPerformances: false,
    improveServices: false,
  });

  function processConsents() {
    const status = window.Didomi?.getCurrentUserStatus();
    if (status) {
      const adPerformances = status.purposes.measure_ad_performance.enabled;
      const improveServices = status.purposes.improve_products.enabled;
      setConsents({ adPerformances, improveServices });
      onConsent('adPerformances', adPerformances);
      onConsent('improveServices', improveServices);
    }
  }

  useEffectOnce(() => {
    processConsents();
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(processConsents);
    window.didomiEventListeners = window.didomiEventListeners || [];
    window.didomiEventListeners.push({
      event: 'consent.changed',
      listener: processConsents,
    });
  });

  return <context.Provider value={{ consents }}>{children}</context.Provider>;
};

export const LFPCookiePolicy: ICookiePolicy = {
  CookieProvider: Provider,
  CookieComponent: () => null,

  useCookieConsents: () => useContext(context).consents,
  getCookieConsents: () => ({}),
  showCookiePolicy: () => window.Didomi?.preferences.show(),
};
